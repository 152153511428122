import 'antd/dist/antd.css';
import HttpsRedirect from 'react-https-redirect';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import PaymentGateway from './PaymentGateway';
import PaymentGatewayResponse from './PaymentGatewayResponse';


function App() {
  return (
    <HttpsRedirect>
      <Router>
        <Switch>
          <Route exact path="/" component={PaymentGateway}></Route>
          <Route path="/responce/:id" component={PaymentGatewayResponse} ></Route>
        </Switch>
      </Router>

    </HttpsRedirect>
  )
}

export default App;
