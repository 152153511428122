import { Button, Col, Form, Input, InputNumber, Row, Select } from 'antd';
import aaa from './images/aaa.png';
import pbc from './images/pbc.jpg';
import "./App.css"

import { add, getMaxId, getSignature } from './util/APIUtils';
import { useState } from 'react';

const { Option } = Select;

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

export default function PaymentGateway() {
  const [loading, setLoading] = useState(false)
  const [signData, setSignData] = useState()
  function clickDiv(el) {
    el.click()
  }

  function handlePay(values) {
    setLoading(true)
    getMaxId("order")
      .then(mx => {
        let signObj = {
          currency: values.currency,
          amount: values.amount,
          key: mx
        };
        getSignature(signObj)
          .then(sendObj => {
            let sendObj1 = { ...sendObj, ...values }
            sendObj1.key = mx
            sendObj1.status = "pending"

            add(sendObj1, "order")
              .then(() => {
                setSignData(sendObj)
              })
              .catch(error => {
                console.error(error)
              })


          })
          .catch(error => {
            console.error(error)
          })

      })
      .catch(error => {
        console.error(error)
      })
  }

  if (signData) {
    return (<form method="post"
      action={signData.sendUrl}
    >
      {Object.keys(signData).map(ifk => <input type="hidden" name={ifk} value={signData[ifk]} />)}
      <input type="submit" name="submit" value="Submit" ref={clickDiv} style={{ display: 'none' }} />
    </form>)
  }
  return (
    <>
      <Row style={{ marginTop: 20, marginBottom: 20, height: 120 }} justify='center'>
        <Col span={2}>
          <img style={{ height: 90, width: 100 }} src={aaa} />
        </Col>
        <Col span={5}>
          <img style={{ height: 100, width: 300 }} src={pbc} />
        </Col>

      </Row>
      <Row style={{height:50,backgroundColor:"#edb20e"}} justify="center">
        <h1 style={{ color: '#DC143C' }}>Payment Gateway</h1>
      </Row>
      <Row style={{height:50,backgroundColor:"#1bbfe0"}} justify="center">
        <h1 style={{ color: '#00008B' }}>Asian Academy of Aeronautics</h1>
      </Row>

      <Row justify="center">
        <div className="div-1">
          <Row style={{ background: "linear-gradient(126deg, rgba(237,178,14,0.48783263305322133) 55%, rgba(244,7,7,0.4458158263305322) 59%, rgba(27,191,224,0.3029586834733894) 63%)", paddingTop: 20, width: 500, }} justify="center">

            <Form
              // {...layout}
              // style={{backgroundColor:"red",width:500}}

              name="AAAPay"
              initialValues={{ currency: "840" }}
              onFinish={handlePay}
            // onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Name"
                name="name"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[{ type: 'email', message: "Invaliddd email" }]}

              >
                <Input />
              </Form.Item>
              <Row>
                <Form.Item
                  label="Amount"
                  name="amount"
                  rules={[{ required: true, message: 'Invalid Amount' }, { type: 'number', min: 1, message: "Invaliddd Amount" }]}
                >
                  <InputNumber min={1} precision={0} />
                </Form.Item>
                <Form.Item
                  name="currency"
                  rules={[{ required: true, message: 'Select currency' }]}
                >
                  <Select
                    style={{
                      width: 80,
                      margin: '0 8px',
                    }}
                  >
                    <Option value="840">USD</Option>
                    <Option value="978">EURO</Option>
                  </Select>
                </Form.Item>

              </Row>



              <Form.Item {...tailLayout}>
                <Button loading={loading} type="primary" htmlType="submit">
                  NEXT
</Button>
              </Form.Item>
            </Form>

          </Row>
        </div>

      </Row>

    </>

  );
}
