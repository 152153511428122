import { Alert, Col, Row } from "antd"
import { useEffect, useState } from "react"
import { get } from "./util/APIUtils"
import aaa from './images/aaa.png';
import pbc from './images/pbc.jpg';


export default function PaymentGatewayResponse(props) {

    const [responseData, setResponseData] = useState()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        get("response", props.match.params.id)
            .then(response => {
                setResponseData(response)
                setLoading(false)
            })
    }, [])
    if (loading) {
        return "Loading . . ."
    }
    function getAlert() {
        if (responseData.ResponseCode === "3") {
            return (<Alert
                message="Error"
                description="Error during transaction processing."
                type="error"
                showIcon
                closable={false}
                style={{ width: "100%" }}
            />)
        }
        if (responseData.ResponseCode === "2") {
            return (<Alert
                message="Warning"
                description="Transaction was declined"
                type="warning"
                showIcon
                closable={false}
                style={{ width: "100%" }}
            />)
        }

        return (<Alert
            message="Success"
            description="YOUR TRANSACTION HAS BEEN SUCCESSFULLY CARRIED OUT PENDING AUTHORISATION"
            type="success"
            showIcon
            closable={false}
            style={{ width: "100%" }}
        />)
    }


    return (
        <>
            <Row style={{ marginTop: 20, marginBottom: 20, height: 150 }} justify='center'>
                <Col span={2}>
                    <img style={{ height: 90, width: 100 }} src={aaa} />
                </Col>
                <Col span={5}>
                    <img style={{ height: 100, width: 300 }} src={pbc} />
                </Col>

            </Row>
            <Row style={{ height: 50, backgroundColor: "#edb20e" }} justify="center">
                <h1 style={{ color: '#DC143C' }}>Payment Gateway</h1>
            </Row>
            <Row style={{ height: 50, backgroundColor: "#1bbfe0" }} justify="center">
                <h1 style={{ color: '#00008B' }}>Asian Academy of Aeronautics</h1>
            </Row>
            <Row justify="center">
                <Row style={{ paddingTop: 20, width: 500 }} justify="center">
                    {getAlert()}

                </Row>
            </Row>
        </>
    )

}